<template>
  <div class="Register__Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Register">
      <div class="Register_Blur" />
      <div>
        <p class="Register__TitleText">{{ title }}</p>
      </div>
      <div class="subtitle">
        <p>{{ label }}</p>
      </div>
      <div v-if="hasForm" class="field">
        <LoginField
          :input-type="'email'"
          :placeholder="placeholder"
          @emailChanged="UpdateEmail($event)"
        />
      </div>
      <div v-else class="subtitle">
        <p>{{ info }}</p>
      </div>
      <div class="Buttons__Container">
        <CTA
          class="Register__Btn"
          :label="back"
          @clickButton="goToLogin"
          :color="'#d7321e'"
        />
        <CTA
          class="Register__Btn"
          v-show="hasForm"
          :label="cta"
          @clickButton="submit"
          :active="!isDisabled"
          :color="'#d7321e'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import LoginField from '../login/components/LoginField'
import CTA from '@/components/button/CTA'
import Parallax from '@/components/parallax/Parallax.vue'

export default {
  name: 'register',
  data () {
    return {
      email: '',
      isDisabled: false,
      succeeded: false
    }
  },
  components: {
    LoginField,
    CTA,
    Parallax
  },
  computed: {
    title () {
      return 'Register'
    },
    label () {
      return 'You will receive an email with instructions to create your password'
    },
    placeholder () {
      return 'email@example.com'
    },

    info () {
      return 'An email has been sent to your address.'
    },
    back () {
      const { succeeded } = this
      return succeeded ? 'go to login' : 'cancel'
    },
    cta () {
      return 'send'
    },

    hasForm () {
      return !this.succeeded
    }
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    UpdateEmail (value) {
      this.email = value
    },
    async submit () {
      const { email } = this
      try {
        this.isDisabled = true
        const baseURL = String(window.location.origin)
        // console.log(baseURL)
        await api.auth.forgotPassword(email, baseURL)
        this.succeeded = true
      } catch (e) {
        console.error('submit error')
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

.Register__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Register__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Register {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  height: 350px;
  width: 365px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
}

.Register_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Register__TitleText {
  font-family: 'Gotham Bold';
  @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 1);
  text-transform: uppercase;
  text-align: center;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    margin-bottom: 0.5rem;
  }
}

.subtitle {
  p {
    font-family: 'Gotham Book';
    @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 1);
    text-align: center;
  }
}

.field {
  height: 20%;
}

.Buttons__Container {
  display: flex;
  justify-content: space-between;
  height: 20%;
}

.Register__Btn {
  height: 100%;
  width: 48%;
}
</style>
